import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const teams = [ //should be retrieved alphabetically from firestore
    {
        value: 'USD',
        label: '$',
    },
];

export default function Base() {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Informazioni Di Base
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="HomeTeam"
                        select
                        required
                        label="Squadra di Casa"
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {teams.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="AwayTeam"
                        select
                        required
                        label="Squadra Ospite"
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {teams.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="HomeGoals"
                        label="Gol di casa"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="AwayGoals"
                        label="Gol di ospiti"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel control={<Switch defaultChecked />} label="Overtime" id='overtime' />
                </Grid>
                {/*only enabled if the overtime switch is on. If a value has been inserted and then the switch is turned off the value should be emptied*/
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="Overtime"
                            label="Tempo di Overtime (Min)"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                }
            </Grid>
        </React.Fragment>
    );
}