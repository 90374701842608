import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import AdminForm from './AdminForm';
import MatchList from './MatchList';
import LoginPage from './LoginPage';
import TeamPage from './TeamPage';
import SignIn from './SignIn';
import CreateMatch from './CreateMatch/CreateMatch.js'

const firebaseConfig = {
  apiKey: "AIzaSyDB3Ikemkc57q_uz2Edr95RVjH9CiUD1ig",
  authDomain: "kalcio-6dfd2.firebaseapp.com",
  projectId: "kalcio-6dfd2",
  storageBucket: "kalcio-6dfd2.appspot.com",
  messagingSenderId: "1094699332115",
  appId: "1:1094699332115:web:adb4adcfe76355229505e6",
  measurementId: "G-G4J9903YEK"
};
firebase.initializeApp(firebaseConfig);
// Import MatchDetails if you created it

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setIsLoggedIn(!!user); // Check if a user is logged in
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<MatchList />} />
        <Route path="/creapartita" element={<CreateMatch />} />
        <Route
          path="/admin"
          element={isLoggedIn ? <AdminForm /> : <Navigate to="/login" replace />}
        />
        <Route path="/squadre/:teamId" element={<TeamPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
