import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { getAuth, signOut } from 'firebase/auth';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, CircularProgress } from '@mui/material';
import ModularTable from './components/ModularTable';


import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';

import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const defaultTheme = createTheme();

const AdminForm = () => {
    // States for managing fetched data
    const [teams, setTeams] = useState([]);
    const [players, setPlayers] = useState([]);
    const [matches, setMatches] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            // Fetch teams, players, and matches from Firestore in parallel
            const fetchTeams = firebase.firestore().collection('teams').get();
            const fetchPlayers = firebase.firestore().collection('players').get();
            const fetchMatches = firebase.firestore().collection('matches').get();

            const [teamsSnap, playersSnap, matchesSnap] = await Promise.all([fetchTeams, fetchPlayers, fetchMatches]);

            setTeams(teamsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            setPlayers(playersSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            setMatches(matchesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            setIsLoading(false);

        };

        fetchData();
    }, []);

    function sortData(rowitems) {
        return rowitems.sort((a, b) => {
            if (a.one.toLowerCase() < b.one.toLowerCase()) {
                return -1;
            } else if (a.one.toLowerCase() > b.one.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            console.error("How tf did we end here?!")
        });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" >
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            Dashboard
                        </Typography>

                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <>
                                {isLoading ? (
                                    <div>Loading data...</div>
                                ) : (
                                    <>
                                        <>
                                            <Grid item xs={12} md={6}>
                                                <Paper sx={{ p: 2 }}>
                                                    {isLoading ? (
                                                        <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
                                                    ) : (
                                                        <ModularTable
                                                            title="Teams"
                                                            heads={[{ id: 'head1', one: "Squadra", two: "Allenatore", three: "Vittorie" }]} // Head structure
                                                            rowitems={sortData(teams.map(team => ({
                                                                id: team.id,
                                                                one: team.name,
                                                                two: team.coach,
                                                                three: team.matchesWon,
                                                                link: `/teams/${team.id}/edit`,
                                                                linktext: 'Edit',
                                                                haslinks: true
                                                            })))}
                                                            haslinks
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>
                                        </>
                                        <>
                                            <br></br>

                                            {/* 2. Players Section */}
                                            <h2>Players</h2>
                                            {/* Display players, add edit button for each */}

                                            {/* 3. Matches Section */}
                                            <h2>Matches</h2>
                                            {/* Display matches, add edit button for each */}

                                            {/* 4. Create Actions Section */}
                                            <h2>Create Actions</h2>
                                            {/* Buttons to create team, player, match */}

                                        </>
                                    </>
                                )}
                                <Button variant="contained" color="secondary" onClick={handleLogout}>
                                    Logout
                                </Button>
                            </>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default AdminForm; 