import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const MatchList = () => {
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        const unsubscribe = firebase.firestore().collection('matches')
            .orderBy('matchDate', 'desc') // Order by matchDate descending
            .onSnapshot((snapshot) => {
                const newMatches = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setMatches(newMatches);
            });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            <h2>Match Results</h2>
            <ul>
                {matches.map((match) => (
                    <li key={match.id}>
                        {match.team1} {match.score1} - {match.score2} {match.team2}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default MatchList;