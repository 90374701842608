import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';


export default function Players({ players }) { // Receive players as a prop
    return (
        <React.Fragment>
            <Title>Giocatori</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Numero</TableCell>
                        <TableCell align="center">Cognome</TableCell>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">Ruolo</TableCell>
                        <TableCell align="right">Gol Segnati</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.map((player) => (
                        <TableRow key={player.id}>
                            <TableCell>{player.numero}</TableCell>
                            <TableCell align="center">{player.cognome}</TableCell>
                            <TableCell align="center">{player.nome}</TableCell>
                            <TableCell align="center">{player.ruolo}</TableCell>
                            <TableCell align="right">{player.golSegnati}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}