import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';



export default function ModularTable({ title, heads, rowitems, haslinks }) { // /!\ only one head needs to be passed
    return (
        <React.Fragment>
            <Title>{title}</Title>
            <Table size="small">
                <TableHead>
                    {heads.map((head) => (
                        <TableRow key={head.id}>
                            <TableCell>{head.one}</TableCell>
                            <TableCell align="center">{head.two}</TableCell>
                            <TableCell align="center">{head.three}</TableCell>
                            <TableCell align="center">{head.four}</TableCell>
                            <TableCell align="right">{head.five}</TableCell>
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {rowitems.map((rowitem) => (
                        <TableRow key={rowitem.id}>
                            <TableCell>{rowitem.one}</TableCell>
                            <TableCell align="center">{rowitem.two}</TableCell>
                            <TableCell align="center">{rowitem.three}</TableCell>
                            <TableCell align="center">{rowitem.four}</TableCell>
                            {!haslinks && (<TableCell align="right">{rowitem.five}</TableCell>)}
                            {haslinks && (
                                <>
                                    {rowitem.five && (<TableCell align="center">{rowitem.five}</TableCell>)}
                                    <Link align="right" color="inherit" href={rowitem.link}>{rowitem.linktext}</Link>
                                </>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </React.Fragment>
    );
}